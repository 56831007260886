import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Videos.css';


var serverUrl = process.env.REACT_APP_SERVER_URL;
//const serverUrl = 'http://localhost:5000';


const Videos = (props)=> {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        axios.get(serverUrl + '/videos')
            .then(response => {
                setVideos(response.data);
            })
            .catch(error => {
                console.error('Error fetching videos: ', error);
            });
    }, []);

    return (
        <div className="video">
            <h1 className="videos-content-title">VIDEOS</h1>
            <div className="videos-content">
                <ul className="video-list">
                    {videos.map((video, index) => (
                        <li key={index}>
                             
                            <Link 
                                className="video-details" 
                                to={{
                                    pathname: '/video-view',
                                    search: '?videoId=' + video.VideoID,
                                }}
                            >
                                <img src={video.Thumbnail} alt={video.Title} />
                                <p>{video.Title.replace(/&#39;/g, "'")}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Videos;
