import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar.js';
import Home from './components/Home.js';
import Videos from './components/Videos.js';
import Blogs from './components/Blogs.js';
import OpenWater from './components/OpenWater.js';
import About from './components/About.js';
import Footer from './components/Footer.js';
import Login from './components/Login.js';
import Signup from './components/SignUp.js';
import VideoView from './components/VideoView.js';
import BlogView from './components/BlogView.js';

//MUI
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    dark: {
      light: '#252525',
      main: '#252525',
      dark: '#252525',
      mainText: '#ffffff',
      secondaryText: '#DF769B'
    },
    light: {
      light: '#FFFFFF',
      main: '#F9F9F9',
      dark: '#EFEFEF',
      mainText: '#1D1D1D',
      secondaryText: '#DF769B'
    },
  },
  typography: {
    "fontFamily": `"Bakbak One", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
   }
});


function App() {

  return (
    <ThemeProvider theme={theme}>
    <Router>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/home" element={<Home/>} />
        <Route path="/videos" element={<Videos/>} />
        <Route path="/video-view" element={<VideoView/>} />
        <Route path="/blogs" element={<Blogs/>} />
        <Route path="/blogs" element={<Blogs/>} />
        <Route path="/openwater" element={<OpenWater/>} />
        <Route path="/blog-view" element={<BlogView/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/signup" element={<Signup/>} />
      </Routes>
      <Footer/>
    </Router>
    </ThemeProvider>

  );
}

export default App;
