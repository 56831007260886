import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Blogs.css'; 
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

const server = process.env.REACT_APP_SERVER_URL;
//const server = 'http://localhost:5000';

const Blogs = () => {
    // State to store the podcast
    const [blogs, setBlogs] = useState([]);

    // Fetch blogs from  the backend when component mounts
    useEffect(() => {
        axios.get(server + '/blogs')
            .then(response => {
                setBlogs(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.error('Error fetching videos: ', error);
            });
    }, []);

    return (
        <Box sx={{ flexGrow: 1 , overflowX:'hidden'}}>
            <Grid container xs={12} sx={{display:{xs: 'none', md: 'flex'}}}>
                <Grid xs={12} height={120}></Grid>
                <Grid xs={1}></Grid>
                <Grid xs={10}>
                    <Typography variant="h1" sx={{color:'dark.mainText', textAlign: 'center', fontSize:'2.5vh'}} >
                        Open Water
                    </Typography>

                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={12} height={80}></Grid>
                <Grid xs={1}></Grid>
                <Grid xs={10}>
                    <ImageList sx={{ width: '80vw', height: '70vh' }} cols={3} rowHeight={164}>
                        {itemData.map((item) => (
                            <ImageListItem key={item.img}>
                            <img
                                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                alt={item.title}
                                loading="lazy"
                            />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={12} height={10}></Grid>
            </Grid>

            <Grid container xs={12} sx={{display:{xs: 'flex', md: 'none'}}}>
                <Grid xs={12} height={120}></Grid>
                <Grid xs={1}></Grid>
                <Grid xs={10}>
                    <Typography variant="h1" sx={{color:'dark.mainText', textAlign: 'center', fontSize:'2.5vh'}} >
                        Open Water
                    </Typography>

                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={12} height={80}></Grid>
                <Grid xs={1}></Grid>
                <Grid xs={10}>
                    <ImageList sx={{ width: '80vw', height: '70vh' }} cols={1} rowHeight={164}>
                        {itemData.map((item) => (
                            <ImageListItem key={item.img}>
                            <img
                                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                alt={item.title}
                                loading="lazy"
                            />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>
                <Grid xs={1}></Grid>
                <Grid xs={12} height={10}></Grid>
            </Grid>
        </Box>
        
    );
};

const itemData = [
    {
      img: `${process.env.PUBLIC_URL}/OpenWater1.jpeg`,
      title: 'Open Water 1',
    },
    {
        img: `${process.env.PUBLIC_URL}/OpenWater2.jpeg`,
        title: 'Open Water 2',
     },
  ];

export default Blogs;
