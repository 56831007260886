import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './About.css'; 
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const server = process.env.REACT_APP_SERVER_URL;
//const server = 'http://localhost:5000';

const About = () => {
    // State to store the podcast
    const [aboutContent, setAboutContent] = useState('');
    const [aboutImage, setAboutImage] = useState('');
    const [aboutEmail, setAboutEmail] = useState('');
    const [aboutYoutube, setAboutYoutube] = useState('');
    const [aboutTwitter, setAboutTwitter] = useState('');
    const [aboutLinked, setAboutLinked] = useState('');
    const [aboutCardAnim, setaboutCardAnim] = useState('flip-in-hor-bottom');
    const [aboutLeftAnim, setaboutLeftAnim] = useState('hide-element');
    const [aboutRightAnim, setaboutRightAnim] = useState('hide-element');

    // Fetch blogs from  the backend when component mounts
    useEffect(() => {
        axios.get(server + '/about')
            .then(response => {
                setAboutContent(response.data[0].Content);
                setAboutImage(response.data[0].Image);
                setAboutEmail(response.data[0].Email);
                setAboutYoutube(response.data[0].Youtube);
                setAboutTwitter(response.data[0].Twitter);
                setAboutLinked(response.data[0].Linked);
            })
            .catch(error => {
                console.error('Error fetching about: ', error);
            });
        const timer = setTimeout(() => {
            setaboutCardAnim('');
            setaboutRightAnim('slide-in-left');
            setaboutLeftAnim('slide-in-right');
            }, 1000);
        const secondtimer = setTimeout(() => {
            setaboutRightAnim('');
            setaboutLeftAnim('');
            }, 1500);
    
        return () => {
            clearTimeout(timer);
            clearTimeout(secondtimer);
        };
    }, []);

    return (
        <div className="about">
             <h1 className="about-title">ABOUT</h1>
             <div className={`about-card ${aboutCardAnim}`}>
                <div className={`about-image-left ${aboutLeftAnim}`}>
                    <img src={aboutImage} alt="Emil" className="about-image"/>
                    <div className="about-links">
                        <button mailto={"mailto:"+aboutEmail}><EmailIcon className="about-link-icon" fontSize='large'/></button>
                        <a href={"https://www."+aboutYoutube}><YouTubeIcon className="about-link-icon" fontSize='large'/></a>
                        <a href={"https://www."+aboutTwitter}><XIcon className="about-link-icon" fontSize='large'/></a>
                        <a href={"https://www."+aboutLinked}><LinkedInIcon className="about-link-icon" fontSize='large'/></a>
                    </div>
                </div>
                <div className={`about-details-right ${aboutRightAnim}`}>
                    <h1 className="about-name">Emil Kalinowski</h1>
                    <p className="about-content">{aboutContent}</p>
                </div>
             </div>
        </div>
    );
};

export default About;
