import React, { useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import './Login.css'; 

const server = process.env.REACT_APP_SERVER_URL;
//const server = 'http://localhost:5000';



const Login = () => {

    const navigate = useNavigate();

    const [username, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [FirstName, setFirstName] = useState('');
    const [LastName, setLastName] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formData = {
            username,
            password,
            FirstName,
            LastName,
        };

         await axios.post(server + '/signup', formData)
          .then(function (user){
            navigate('/home');
          })
          .catch((error)=>{
            console.error('Error submitting form: ', error);
          });
      };

    return (
        <div className="login">
            <div className="card">
                <h2 >SIGN UP</h2>
                <form onSubmit={handleSubmit} >
                    <input type="text" id="firstname" name="firstname" placeholder="First Name" onChange={(e)=> setFirstName(e.target.value)} required/>
                    <input type="text" id="lastname" name="lastname" placeholder="Last Name" onChange={(e)=> setLastName(e.target.value)} required/>
                    <input type="email" id="email" name="email" placeholder="Email" onChange={(e)=> setEmail(e.target.value)} required/>
                    <input type="password" id="password" name="password" placeholder="Password" onChange={(e)=> setPassword(e.target.value)} required/>
                    <button type="submit">SIGN UP</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
