import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import './Navbar.css';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';


function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(true);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  var r = document.querySelector(':root');

  const toggleDarkMode = () =>{
    r.style.setProperty('--mainBG', '#2D2D2D');
    r.style.setProperty('--elementBG', '#252525');
    r.style.setProperty('--elementFont', '#ffffff');
    setIsDarkMode(true);
  };

  const toggleLightMode = () =>{
    r.style.setProperty('--mainBG', '#ffffff');
    r.style.setProperty('--elementBG', '#ececec');
    r.style.setProperty('--elementFont', '#252525');
    setIsDarkMode(false);
  };

  return (
    <AppBar position="fixed" className='navbar-appbar'>
      <Container className='navbar' >
        <Toolbar disableGutters >
            {/*LOGO*/}
            <Link to="/home" className="logo">
                    <h1 className="logo-emil">EMIL</h1>
                    <h1 className="logo-kalinowski">KALINOWSKI</h1>
            </Link>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} >
                <Link to="/home" className='navlinks'>HOME</Link>
                <Link to="/videos" className='navlinks'>VIDEOS</Link>
                <Link to="/blogs" className='navlinks'>ARTICLES</Link>
                <Link to="/openwater" className='navlinks'>OPEN WATER</Link>
                <Link to="/about" className='navlinks'>ABOUT</Link>
            </Box>
            <Box className='navbar-menu-icon' sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} >
                <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                
                >
                <MenuIcon />
                </IconButton>
                <Menu
                id="menu-appbar"
                className='navbar-sml-menu'
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: { xs: 'block', md: 'none' },
                }}
                >
                    <MenuItem onClick={handleCloseNavMenu}>
                    <Link to="/home" className='navlinks-sm'>HOME</Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/videos" className='navlinks-sm'>VIDEOS</Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/blogs" className='navlinks-sm'>ARTICLES</Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/openwater" className='navlinks-sm'>OPEN WATER</Link>
                    </MenuItem>
                    <MenuItem onClick={handleCloseNavMenu}>
                <Link to="/about" className='navlinks-sm'>ABOUT</Link>
                    </MenuItem>
                    {
                isDarkMode ?  
                <MenuItem onClick={toggleLightMode}>
                <Link to="/" className='navlinks-sm'>LIGHT MODE</Link>
                  </MenuItem> 
              : 
              <MenuItem onClick={toggleDarkMode}>
                <Link to="/" className='navlinks-sm'>DARK MODE</Link>
                  </MenuItem> 
              }
                </Menu>
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }} >
              {
                isDarkMode ?  
                <IconButton
                size="large"
                aria-label="dark mode toggle"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleLightMode}
                color="inherit"
                >
                <LightModeIcon />
              </IconButton> 
              : 
              <IconButton
                size="large"
                aria-label="dark mode toggle"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDarkMode}
                color="inherit"
                >
                <DarkModeIcon className='darkmode-button'/>
              </IconButton> 
              }
             
            </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;