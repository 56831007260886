import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import './Login.css'; 

const server = process.env.REACT_APP_SERVER_URL;
//const server = 'http://localhost:5000';

const Login = () => {

    const navigate = useNavigate();

    const [username, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {username,password};
        try {
            await axios.post(server + '/login', formData);
            navigate('/home');
        } catch (error) {
            if (error.response) {
                console.error(error.response.data);
                console.error(error.response.status);
                console.error(error.response.headers);
  
              } else if (error.request) {
                console.error(error.request);
              } else {
                console.error('Error', error.message);
              }
              console.error(error.config);
        }
      };

    return (
        <div className="login">
            <div className="card">
                <h2 >LOGIN</h2>
                <form onSubmit={handleSubmit} >
                    <input type="email" id="email" name="email" placeholder="Email" onChange={(e)=> setEmail(e.target.value)} required/>
                    <input type="password" id="password" name="password" placeholder="Password" onChange={(e)=> setPassword(e.target.value)} required/>
                    <button type="submit">LOGIN</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
