import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Blogs.css'; 

const server = process.env.REACT_APP_SERVER_URL;
//const server = 'http://localhost:5000';

const Blogs = () => {
    // State to store the podcast
    const [blogs, setBlogs] = useState([]);

    // Fetch blogs from  the backend when component mounts
    useEffect(() => {
        axios.get(server + '/blogs')
            .then(response => {
                setBlogs(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.error('Error fetching videos: ', error);
            });
    }, []);

    return (
        <div className="blogs">
             <h1 className="latest-blogs-title">ARTICLES</h1>
            <div className="latest-blogs">
                <ul className="blog-list">
                    {blogs.map((blog, index) => (
                        <li key={index}>
                            <Link 
                                className="blog-details" 
                                to={{
                                    pathname: '/blog-view',
                                    search: '?blogId=' + blog._id,
                                }}
                            >
                                 <img src={blog.Image_URL} alt={blog.Title} />
                                <p>{blog.Title.toUpperCase()}</p>
                            </Link>
                           
                        </li>
                    ))}
                    
                </ul>
            </div>
        </div>
    );
};

export default Blogs;
