import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import './VideoView.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

var serverUrl = process.env.REACT_APP_SERVER_URL;
//const serverUrl = 'http://localhost:5000';


const VideoView = ()=> {
    const location = useLocation();

    const [videos, setVideos] = useState([]);

    useEffect(() => {
      axios.get(serverUrl + '/videos')
          .then(response => {
              setVideos(response.data);
          })
          .catch(error => {
              console.error('Error fetching videos: ', error);
          });
  }, []);

    const videoId = new URLSearchParams(location.search).get('videoId');
    const opts = {
        height: '390',
        width: '640',
        playerVars: {
          autoplay: 1,
        },
      };
    
    const handlePlayer = async (event) => {
        event.target.pauseVideo();
      };

    return (
        <Box sx={{ width: '100%' }} className="videoView">
            <Breadcrumbs aria-label="breadcrumb" className='video-breadcrumbs'>
                <Link underline="hover" color="inherit" to="/home">Home</Link>
                <Link underline="hover" color="inherit" to="/videos">Videos</Link>
                <Link underline="hover" color="text.primary" to={{
                                    pathname: '/video-view',
                                    search: '?videoId=' + videoId,
                                }} aria-current="page">{videoId}</Link>
            </Breadcrumbs>
            <Grid container spacing={2} className="video-grid-container" >
                <Grid item className="video-grid-item" columns={{ xs: 12, md: 12 }}>
                    <YouTube videoId={videoId} opts={opts} onReady={handlePlayer} className="video-player"/>
                </Grid>
            </Grid>
            <Grid container spacing={2} columns={12} className="video-list-grid-container">
                <ul className="video-list">
                    {videos.map((video, index) => (
                        <Grid item columns={{ xs: 8, md: 12 }}>
                        <li key={index}>
                            <img src={video.Thumbnail} alt={video.Title} />
                            <Link 
                                className="video-details" 
                                to={{
                                    pathname: '/video-view',
                                    search: '?videoId=' + video.VideoID,
                                }}
                            >
                                <p>{video.Title.replace(/&#39;/g, "'").toUpperCase()}</p>
                            </Link>
                        </li>
                        </Grid>
                    ))}
                </ul>
            </Grid>
        </Box>
    );

    
};



export default VideoView;
