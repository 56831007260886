import React, { useState, useEffect, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Home.css'; 
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';



const server = process.env.REACT_APP_SERVER_URL;
//const server = 'http://localhost:5000';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });



const Home = () => {
    // State to store the youtube videos
    const [videos, setVideos] = useState([]);

    // State to store the podcast
    const [blogs, setBlogs] = useState([]);

    const [subscriberEmail, setSubscriberEmail] = useState('');
    const [subscriberNewsLetter, setsubscriberNewsLetter] = useState(false);
    const [subscriberVideoUpdate, setsubscriberVideoUpdate] = useState(false);
    const [subscriberPodcastUpdate, setsubscriberPodcastUpdate] = useState(false);
    const [subscriberBlogsUpdate, setsubscriberBlogsUpdate] = useState(false);
    const [subscriberEventsUpdate, setsubscriberEventsUpdate] = useState(false);
    const [subscriberMerchUpdate, setsubscriberMerchUpdate] = useState(false);
    const [subscriberCourseUpdate, setsubscriberCourseUpdate] = useState(false);
    
    const [aboutYoutube, setAboutYoutube] = useState('');
    const [aboutTwitter, setAboutTwitter] = useState('');
    const [aboutLinked, setAboutLinked] = useState('');

    const [open, setOpen] = useState(false);
    const [alertIsError, setAlertIsError] = useState(false);

    const [animation, setAnimation] = useState('swing-in-top-fwd');
    const [bannercontentClass, setbannerContentAnim] = useState('hide-element');


      const handleClose = () => {
        setOpen(false);
      };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            subscriberEmail,
            subscriberNewsLetter,
            subscriberVideoUpdate,
            subscriberPodcastUpdate,
            subscriberBlogsUpdate,
            subscriberEventsUpdate,
            subscriberMerchUpdate,
            subscriberCourseUpdate,
          };
        try {
          await axios.post(server + '/subscriber', formData);
          setAlertIsError(false);
          setOpen(true);
        } catch (error) {
          console.error('Error submitting form: ', error);
          setAlertIsError(true);
          setOpen(true);
        }
      };

    // Fetch youtube videos when component mounts
    useEffect(() => {
        // Fetch videos, podcasts, and blogs from your backend or API
        axios.get(server + '/about')
        .then(response => {
            setAboutYoutube(response.data[0].Youtube);
            setAboutTwitter(response.data[0].Twitter);
            setAboutLinked(response.data[0].Linked);
        })
        .catch(error => {
            console.error('Error fetching about: ', error);
        });

        axios.get(server + '/latest-videos')
            .then(response => {
                setVideos(response.data);
            })
            .catch(error => {
                console.error('Error fetching videos: ', error);
            });

        axios.get(server + '/latest-blogs')
            .then(response => {
                setBlogs(response.data);
            })
            .catch(error => {
                console.error('Error fetching blogs', error);
            });
        const firsttimer = setTimeout(() => {
                setAnimation('');
                setbannerContentAnim('slide-in-left');
            }, 500); // Remove the class after 500ms
        const secondtimer = setTimeout(() => {
                setbannerContentAnim('');
            }, 1000); // Remove the class after 500ms
    
            return () => {
                clearTimeout(firsttimer);
                clearTimeout(secondtimer);
            };
    }, []);


    return (
        <div className="home">
            <div className={`banner ${animation}`}>
                <img src="EmilBanner.png" alt="EmilBanner" className="banner-img" />
                <div className={`banner-content ${bannercontentClass}`} >
                    <p className="banner-content-text">Helping you understand how the 2007 malfunction of the monetary system - and its continuing disorder - affects finance, economy, politics and society.</p>
                    <form onSubmit={handleSubmit} className="subscribe">
                        <input type="email" placeholder="Enter your email" value={subscriberEmail} onChange={(e) => {
                        setsubscriberNewsLetter(true);
                        setsubscriberVideoUpdate(true);
                        setsubscriberPodcastUpdate(true);
                        setsubscriberBlogsUpdate(true);
                        setsubscriberEventsUpdate(true);
                        setsubscriberMerchUpdate(true);
                        setsubscriberCourseUpdate(true);
                        setSubscriberEmail(e.target.value);
                        }} />
                        <button type="submit">SUBSCRIBE</button>
                    </form>
                    <div item className="social-links">
                    <Link to={aboutYoutube}><img src="youtube-icon.png" alt="youtube link" className="social-link-icon" /></Link>
                    <Link to={aboutTwitter}><img src="twitterx-icon.png" alt="twitter link" className="social-link-icon" /></Link>
                    <Link to={aboutLinked}><img src="linkedin-icon.png" alt="linkedin link" className="social-link-icon" /></Link>
                    </div>
                </div>
            </div>
            <h1 className="home-latest-videos-content-title">LATEST VIDEOS</h1>
            <div className="home-latest-videos-content">
                <ul className="home-video-list">
                    {videos.map((video, index) => (
                        <li key={index}>
                             <Link 
                                className="video-details" 
                                to={{
                                    pathname: '/video-view',
                                    search: '?videoId=' + video.VideoID,
                                }}
                            >
                                <img src={video.thumbnail} alt={video.title} />
                                <p>{video.title.replace(/&#39;/g, "'")}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <h1 className="home-latest-blogs-title">LATEST ARTICLES</h1>
            <div className="home-latest-blogs">
                <ul className="home-blog-list">
                    {blogs.slice(0,5).map((blog, index) => (
                        <li key={index}>
                            <Link to={{
                                    pathname: '/blog-view',
                                    search: '?blogId=' + blog._id,
                                }}>
                                <img src={blog.Image_URL} alt={blog.Title} />
                                <p>{blog.Title}</p>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
         {alertIsError ? 
            <DialogTitle className='dialog-content'>{"Subscription Failed"}</DialogTitle>
          : 
          <DialogTitle>{"Subscription Success"}</DialogTitle>}
        <DialogContent className='dialog-content'>
        {alertIsError ? 
            <DialogContentText id="alert-dialog-slide-description">
                Please check your email address and try again.
            </DialogContentText> 
          : 
            <DialogContentText id="alert-dialog-slide-description">
                 Thank you for subscribing to Emil Kalinowski!
            </DialogContentText>}
        </DialogContent>
        <DialogActions className='dialog-content'>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
        </div>
    );
};

export default Home;
