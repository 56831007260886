import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import './BlogView.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Grid from '@mui/material/Grid';
import parse from 'html-react-parser';

const server = process.env.REACT_APP_SERVER_URL;
//const server = 'http://localhost:5000';


const VideoView = ()=> {
    const location = useLocation();
    const blogId = new URLSearchParams(location.search).get('blogId');

    // State to store the podcast
    const [blogs, setBlogs] = useState({});
    const [blogTitle, setBlogTitle] = useState('');
    const [blogDate, setBlogDate] = useState('');
    const [blogContent, setBlogContent] = useState([]);
    const [blogPostedFrom, setPostedFrom] = useState('');


    useEffect(() => {
        axios.get(`${server}/blogs/id?id=${blogId}`)
            .then(response => {
                setBlogs(response.data);
                setBlogTitle(response.data.Title);
                let dateCreated = new Date(response.data.DateCreated);
                let dateMDY = `${dateCreated.getDate()}/${dateCreated.getMonth() + 1}/${dateCreated.getFullYear()}`;
                setPostedFrom(response.data.PostedFrom);
                const contentToAdd = response.data.Content.map((content,index)=>{
                    
                    if(content.ContentTag === 'img') {
                        return(
                            `<${content.ContentTag} src=${content.ContentValue} alt=${content.ContentValue}/>`);
                    }
                    else {
                        return(
                            `<${content.ContentTag}>${content.ContentValue}</${content.ContentTag}>`);
                    }
                })
                setBlogContent(contentToAdd);
                setBlogDate(dateMDY);
            })
            .catch(error => {
                console.error('Error fetching videos: ', error);
            });
    }, [blogId]);

    return (
        <Grid container className='blog-view' rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Breadcrumbs aria-label="breadcrumb" className='blog-breadcrumbs'>
                <Link underline="hover" color="inherit" to="/home">Home</Link>
                <Link underline="hover" color="inherit" to="/blogs">Articles</Link>
                <Link underline="hover" color="text.primary" to={{
                                    pathname: '/blog-view',
                                    search: '?blogId=' + blogId,
                                }} aria-current="page">{blogTitle}</Link>
            </Breadcrumbs>
            <Grid item  xs={12} className='blog-view-image'>
            <img src={blogs.Image_URL} alt='blog'/>
            </Grid>
            <Grid item  xs={12} className='blog-view-heading'>
            <h1 className='blog-view-title'>{blogTitle.toUpperCase()}</h1>
            <h2 className='blog-view-author'>{"By " + blogs.Author}</h2>
            <h4 className='blog-view-date'>{"Date Posted: " + blogDate}</h4>
            <Link to={blogPostedFrom} className='blog-view-postedfrom'>{"Posted From: " + blogPostedFrom}</Link>
            {blogContent.map((blogcnt, index) => {
    return <div key={index} className='blog-view-content'>{parse(blogcnt)}</div>;
})}
            </Grid>
        </Grid>
    );
};



export default VideoView;
